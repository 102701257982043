// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktuellt-js": () => import("./../../../src/pages/aktuellt.js" /* webpackChunkName: "component---src-pages-aktuellt-js" */),
  "component---src-pages-engagera-dig-js": () => import("./../../../src/pages/engagera-dig.js" /* webpackChunkName: "component---src-pages-engagera-dig-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakta-oss-js": () => import("./../../../src/pages/kontakta-oss.js" /* webpackChunkName: "component---src-pages-kontakta-oss-js" */),
  "component---src-pages-om-oss-js": () => import("./../../../src/pages/om-oss.js" /* webpackChunkName: "component---src-pages-om-oss-js" */),
  "component---src-pages-tantjouren-js": () => import("./../../../src/pages/tantjouren.js" /* webpackChunkName: "component---src-pages-tantjouren-js" */),
  "component---src-pages-tjejjouren-js": () => import("./../../../src/pages/tjejjouren.js" /* webpackChunkName: "component---src-pages-tjejjouren-js" */),
  "component---src-pages-valdmotkvinnor-js": () => import("./../../../src/pages/valdmotkvinnor.js" /* webpackChunkName: "component---src-pages-valdmotkvinnor-js" */),
  "component---src-templates-news-template-js": () => import("./../../../src/templates/newsTemplate.js" /* webpackChunkName: "component---src-templates-news-template-js" */)
}

